import React from 'react'
import { graphql } from 'gatsby'

import { msalConfig, loginRequest } from './../utility/auth/msalConfig';
import { MsalProvider } from '../utility/auth/msalContext';

import classes from '../styles/faq.module.sass'
import { renderAst } from '../markdown'

import Wrapper from '../components/wrapper'


export default ({data}) => 
{
    const faqHtml = renderAst(data.markdownRemark.htmlAst);
    const { i18n, footer } = data.site.siteMetadata;
    
    return (
        <MsalProvider
            config={msalConfig}
            scopes={loginRequest}
            >
            <Wrapper>
                <div className={classes.container}>
                <h1 className={classes.pagetitle}>{ i18n.pages.faq }</h1>
                    {faqHtml} 
                </div>
                { i18n.pages.faqContactUs && footer.email && (
                        <p className={classes.pagetitle}>{ i18n.pages.faqContactUs }: <a className={classes.link} href={'mailto:' + footer.email}>{footer.email}</a></p>) }

            </Wrapper>
        </MsalProvider>
    )
}

export const pageQuery = graphql`
    query($slug: String!) {
        site {
            siteMetadata {
                courseId
                footer {
                    email
                }
                i18n {
                    pages {
                        faq
                        faqContactUs
                    }
                }
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            htmlAst
        }
    }
`
